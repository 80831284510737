<template>
    <div class="modal-download-wrapper">
        <div class="loading-spin" v-if="loadingDownload">
            <i class="fa fa-spin fa-refresh"></i>
        </div>
        <b-form @submit.prevent="downloadPeriod">
            <div class="row align-items-end">
                <b-col md="9">
                    <b-form-group>
                        <b-row>
                            <b-col>
                                <date-picker v-model="formRangeDate.from" placeholder="Dari Tanggal"
                                    :config="configFromDate"></date-picker>
                            </b-col>
                            <b-col>
                                <date-picker v-model="formRangeDate.to" placeholder="Hingga Tanggal"
                                    :config="configToDate"></date-picker>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group>
                        <button :disabled="!formRangeDate.from || !formRangeDate.to"
                            class="btn btn-primary w-100">Download</button>
                    </b-form-group>
                </b-col>
            </div>
        </b-form>
        <hr>
        <b-form @submit.prevent="downloadPerDate">
            <div class="row align-items-end">
                <b-col md="9">
                    <b-form-group label="Per Tanggal">
                        <date-picker :config="configDate" v-model="selectedDate" placeholder="Tanggal"></date-picker>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group>
                        <button :disabled="!selectedDate" class="btn btn-primary w-100">Download</button>
                    </b-form-group>
                </b-col>
            </div>
        </b-form>
    </div>
</template>

<script>
import axiosClient from '../../config/api'

export default {
    data: () => {
        return {
            loadingDownload: false,
            formRangeDate: {
                from: null,
                to: null
            },
            selectedDate: null,
            configFromDate: {
                format: "D MMM YYYY",
                useCurrent: true,
                maxDate: new Date()
            },
            configToDate: {
                format: "D MMM YYYY",
                useCurrent: true,
            },
            configDate: {
                format: "D MMM YYYY",
                useCurrent: true,
            },
        }
    },
    methods: {
        async downloadPeriod() {
            const FROM = this.$moment(this.formRangeDate.from).format('YYYY-MM-DD')
            const TO = this.$moment(this.formRangeDate.to).format('YYYY-MM-DD')
            try {
                this.loadingDownload = true
                const response = await axiosClient({
                    url: `internal-memo/memo/laporan/perbaikan-by-month`,
                    method: 'GET',
                    params: {
                        startDate: FROM,
                        endDate: TO
                    },
                    responseType: 'blob',
                })
                if (response.status === 200) {
                    const url = URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `laporan-fpp-${FROM}-sd-${TO}.xlsx`
                    )
                    document.body.appendChild(link)
                    link.click()
                }
            } catch (error) {
                console.log(error)
            }
            finally {
                this.loadingDownload = false
            }

        },
        async downloadPerDate() {
            const DATE = this.$moment(this.selectedDate).format('YYYY-MM-DD')
            try {
                this.loadingDownload = true
                const response = await axiosClient({
                    url: `internal-memo/memo/laporan/perbaikan-by-date`,
                    method: 'GET',
                    params: {
                        tanggal: DATE,
                    },
                    responseType: 'blob',
                })
                if (response.status === 200) {
                    const url = URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `laporan-fpp-${DATE}.xlsx`
                    )
                    document.body.appendChild(link)
                    link.click()
                }
            } catch (error) {
                console.log(error)
            }
            finally {
                this.loadingDownload = false
            }

        },

    }
}
</script>

<style>
.modal-download-wrapper {
    position: relative;
}

.loading-spin {
    position: absolute;
    z-index: 60;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-spin i {
    font-size: 22px;
}
</style>

