<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
    <b-card v-else>
      <template v-slot:header>
        <h5>
          Arsip FPP (Formulir Pengajuan Permintaan, Perbaikan & Penggantian)
        </h5>
      </template>
      <!-- NEW FILTER -->
      <!-- BLM KELAR -->
      <div class="mb-3 row">
        <div class="col-lg-11">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <div class="d-flex align-items-center">
                  <div class="w-100">
                    <date-picker v-model="formFilter.from" placeholder="Dari Tanggal"
                      :config="configFilter"></date-picker>
                  </div>
                  <span class="mx-2">s/d</span>
                  <div class="w-100">
                    <date-picker v-model="formFilter.until" placeholder="Sampai Tanggal"
                      :config="configFilter"></date-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group">
                <b-form-select size="md" v-model="formFilter.selectFilter" :plain="false">
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>Pencarian berdasarkan</b-form-select-option>
                  </template>

                  <b-form-select-option v-for="option in optionsFilter" v-bind:key="option.value"
                    v-bind:value="option.value">
                    {{ option.label }}
                  </b-form-select-option>
                </b-form-select>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="form-group" v-if="formFilter.selectFilter == 'status'">
                <b-form-select size="md" class="mr-2" v-model="formFilter.selectStatus" @change="changeQuery('flag_status',
                  $event)" :plain="false">
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>Pilih Status</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="option in optionsStatus" v-bind:key="option.value"
                    v-bind:value="option.value">
                    {{ option.label }}
                  </b-form-select-option>
                </b-form-select>
              </div>
              <div class="form-group" v-if="formFilter.selectFilter == 'kacab'">
                <multiselect class="w-100 my-multiselect" placeholder="Pilih Kacab" label="text" track-by="id"
                  v-model="formFilter.optionsKacab" :show-labels="false" :close-on-select="true" :clear-on-select="false"
                  :multiple="false" :options="optionsKacab" @input="changeQuery('created_by',
                    $event.id)"></multiselect>
              </div>
              <div class="form-group" v-if="formFilter.selectFilter == 'kcs'">
                <multiselect class="w-100 my-multiselect" placeholder="Pilih Kcs" label="text" track-by="id"
                  v-model="formFilter.optionsKcs" :show-labels="false" :close-on-select="true" :clear-on-select="false"
                  :multiple="false" :options="optionsKcs" @input="changeQuery('kepala_cabang_senior',
                    $event.id)"></multiselect>
              </div>


              <div class="form-group" v-if="formFilter.selectFilter == 'kabkot'">
                <multiselect class="w-100 my-multiselect" placeholder="Pilih Kabupaten/Kota" label="text" track-by="id"
                  v-model="formFilter.selectKabKot" :show-labels="false" :close-on-select="true" :clear-on-select="false"
                  :multiple="false" :options="filtered_cities" @input="changeQuery('kabupaten_kota_id',
                    $event.id)"></multiselect>
              </div>
              <!-- Filter Kategori -->
              <div class="form-group" v-if="formFilter.selectFilter == 'kategori'">
                <b-form-select size=" md" class="w-100" v-model="formFilter.selectKategori" @change="changeQuery('id_kategori_fpp',
                  $event)" :plain="false">
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>Pilih Kategori</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="option in getKategori" v-bind:key="option.id" v-bind:value="option.id">
                    {{ option.name }}
                  </b-form-select-option>
                </b-form-select>
              </div>
              <!-- Filter Jenis Kategori -->
              <div class="form-group" v-if="formFilter.selectFilter == 'jenis-kategori'">
                <b-form-select size="md" class="w-100" v-model="formFilter.selectJenisKategori" @change="changeQuery('id_kategori_jenis_fpp',
                  $event)" :plain="false">
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>Pilih Jenis Pengajuan</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="option in optionsKategoriJenis" v-bind:key="option.id"
                    v-bind:value="option.id">
                    {{ option.name }}
                  </b-form-select-option>
                </b-form-select>
              </div>
              <!-- Filter Jenis Sub Kategori -->
              <div class="form-group" v-if="formFilter.selectFilter == 'sub-kategori'">
                <b-form-select size="md" class="w-100" v-model="formFilter.selectSubKategori" @change="changeQuery('id_kategori_sub_fpp',
                  $event)" :plain="false">
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>Pilih Pengajuan</b-form-select-option>
                  </template>

                  <b-form-select-option v-for="option in optionsSubKategori" v-bind:key="option.id"
                    v-bind:value="option.id">
                    {{ option.name }}
                  </b-form-select-option>
                </b-form-select>
              </div>
              <!-- Filter Cabang -->
              <template v-if="formFilter.selectFilter == 'cabang'">
                <!-- <multiselect class="w-100 my-multiselect" placeholder="Pilih Cabang" label="name" track-by="id"
                  v-model="formFilter.selectCabang" :show-labels="false" :close-on-select="false" :clear-on-select="false"
                  :preserve-search="true" :multiple="true" :options="optionsCabang" @select="onSelect($event)"
                  @remove="onRemove($event)" @input="changeQuery('id_cabang', $event)">
                  <template class="checkbox-label" slot="option" slot-scope="scope">
                    {{ scope.option.name }}
                    <input class="custom-check" type="checkbox" v-model="scope.option.checked" @focus.prevent />
                  </template>
                  <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                      v-if="values.length" v-show="!isOpen">{{ values.length }} cabang ditandai</span></template>
                </multiselect> -->
                <multiselect multiple v-model="formFilter.selectCabang" :options="optionsCabang" :internal-search="true"
                  :allow-empty="true" deselect-label="" :close-on-select="false" select-label="" label="name"
                  track-by="id" placeholder="pilih cabang" />
              </template>
            </div>
          </div>

        </div>
        <div class="col-lg-1">
          <div class="d-flex justify-content-end">
            <button @click="filterData" class="btn btn-primary mr-2">
              <span class="d-md-none d-block">
                <i class="fa fa-search"></i> Cari
              </span>
              <span class="d-none d-md-block">
                <i class="fa fa-search"></i>
              </span>
            </button>
            <button @click="onReset" class="btn btn-secondary">
              <i class="fa fa-refresh"></i>
            </button>
          </div>

        </div>
      </div>
      <!-- NEW FILTER -->

      <!-- Table Pengajuan -->
      <div class="d-flex justify-content-end mb-2">
        <button @click="showModalLaporan" class="btn btn-primary">
          <i class="fa fa-file-excel-o"></i>
          Laporan
        </button>
      </div>
      <b-table bordered hover show-empty :busy="isBusy" :items="archive"
        :fields="roleShowCabang.includes(role) ? fieldsPusat : fieldsCb" :sort-by.sync="sortBy"
        class="mb-3 my-custom-table table-daftar-fpp">
        <template #empty="scope"> Data tidak ditemukan </template>
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner small class="align-middle" variant="secondary"></b-spinner>
            <span class="font-weight-bold"> Harap tunggu...</span>
          </div>
        </template>
        <template v-slot:cell(tanggal)="row">
          {{ row.item.tanggal | moment("D MMM Y, HH:mm") }}
        </template>

        <template v-slot:cell(no_pengajuan)="row">
          <div class="text-left">
            <div>{{ row.item.no_pengajuan }}</div>
            <small><b>{{ row.item.vendor_type }}</b></small>
          </div>
        </template>
        <template v-slot:cell(jenis_pengajuan)="row">
          {{ row.item.jenis_pengajuan }}
        </template>

        <template v-slot:cell(status)="row">
          <div v-if="row.item.status == 0">
            <span class="badge badge-warning">Pengajuan Ditinjau Ulang</span>
          </div>

          <div v-else-if="row.item.status == 1">
            <span class="badge badge-primary">Pengajuan Disetujui (1/2)</span>
          </div>

          <div v-else-if="row.item.status == 2">
            <span class="badge badge-primary text-white">Pengajuan Disetujui (2/2)</span>
          </div>

          <div v-else-if="row.item.status == 3">
            <span class="badge badge-info text-white">Pengajuan Diproses</span>
          </div>

          <div v-else-if="row.item.status == 4">
            <span class="badge badge-success text-white">Pengajuan selesai</span>
          </div>

          <div v-else-if="row.item.status == 8">
            <span class="badge badge-secondary">Pengajuan Dibatalkan</span>
          </div>

          <div v-else-if="row.item.status == 10">
            <span class="badge badge-dark">Pengajuan Ditolak</span>
          </div>

          <div v-else-if="row.item.status == 11">
            <span class="badge badge-danger">Pengajuan Dijadwalkan Ulang</span>
          </div>

          <div v-else-if="row.item.status == 12">
            <span class="badge badge-success">Petugas Hadir ({{ row.item.total_maintenance }}/{{
              row.item.count_attendance_maintenance
            }})
            </span>
          </div>

          <div v-else-if="row.item.status == 13">
            <span class="badge badge-success">Menunggu Penilaian</span>
          </div>
        </template>

        <template v-slot:cell(aksi)="row">
          <b-dropdown id="dropdown-right" right text="Right align" variant="pgiBtn" class="m-0">
            <template slot="button-content">
              <i class="py-0 fa fa-bars"></i>
            </template>
            <!-- "row.item.status == 3 &&
              row.item.timeout !== null &&
              row.item.timeout > 0
              " -->
            <template v-if="1 === 1">
              <b-dropdown-item href="#" @click="showModalDetail(`${row.item.id}`)">Detail</b-dropdown-item>
              <template>
                <b-dropdown-item href="#" @click="showModalUpdateUserMaintenance(row.item.id, { data: row.item })">
                  Ubah Petugas & Tanggal
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="showModalUpdateMaterial(`${row.item.id}`)">
                  Ubah Material/Bahan
                </b-dropdown-item>
              </template>
            </template>

            <template v-else>
              <b-dropdown-item href="#" @click="showModalDetail(`${row.item.id}`)">Detail</b-dropdown-item>
            </template>
          </b-dropdown>
        </template>
      </b-table>

      <div class="pagination-wrapper d-flex align-items-center justify-content-between">
        <b-input-group prepend="Menampilkan" class="mt-3 font-weight-bold">
          <b-input-group-append>
            <b-input-group-text class="bg-white">
              <strong class="mr-1">{{ totalViewText }}</strong>
              <span class="mr-1">dari</span>
              <strong>{{ rows }}</strong>
            </b-input-group-text>
            <b-input-group-text>entri</b-input-group-text>
          </b-input-group-append>
        </b-input-group>

        <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
          @input="onPageChange"></b-pagination>
      </div>
    </b-card>

    <!-- Modal Detail -->
    <b-modal id="m-detail" size="xl" title="Detail Persetujuan" ref="my-modal" @hidden="hideModal" no-close-on-backdrop
      no-close-on-esc hide-footer>
      <LoadingWidget v-if="initStatusDetail != 1" :status="initStatusDetail" @init="showModalDetail" />
      <template v-else>
        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">No. Pengajuan</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.noPengajuan }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Cabang</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.cabang }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Divisi</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.divisi }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Kategori</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.namaKategori }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Pengajuan</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.pengajuan }}</span>
          </b-col>
        </b-row>

        <!-- <template v-if="form.id_kategori_jenis_fpp !== 1"> -->
        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Jumlah</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.kuantitas }}</span>
          </b-col>
        </b-row>
        <!-- </template> -->

        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Catatan</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.catatan }}</span>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <b-col cols="12" sm="12" md="6" lg="4" xl="3">
            <span class="font-weight-bold">Estimasi Pengerjaan</span>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="8" xl="9">
            <span>{{ form.estimasi }}</span>
          </b-col>
        </b-row>

        <template v-if="form.status != 8">
          <b-row class="mb-2">
            <b-col cols="12" sm="12" md="6" lg="4" xl="3">
              <span class="font-weight-bold">Sisa Waktu Pengerjaan</span>
            </b-col>

            <b-col cols="12" sm="12" md="6" lg="8" xl="9">
              <div v-if="form.status != 4 && form.status != 8 && form.status != 10">
                <template v-if="timeDifference < 0">
                  <span v-show="!loadCountDown" class="text-danger">
                    {{ form.timerInProgress }}</span>
                </template>

                <template v-else-if="timeDifference > 0">
                  <span v-show="!loadCountDown">{{
                    form.timerInProgress
                  }}</span>
                </template>

                <span v-show="loadCountDown" class="spinner-border spinner-border-sm"></span>
              </div>

              <div v-else>
                <template v-if="calc > 0">
                  <span class="text-danger">{{ form.timerFinish }}</span>
                </template>

                <template v-else-if="calc < 0">
                  <b-badge variant="success">estimasi waktu pengerjaan terpenuhi
                    <i class="fa fa-check"></i></b-badge>
                </template>
              </div>
            </b-col>
          </b-row>
        </template>

        <template v-if="form.status == 10">
          <b-row>
            <b-col cols="12" sm="12" md="6" lg="4" xl="3">
              <span class="font-weight-bold">Keterangan</span>
            </b-col>
            <b-col cols="12" sm="12" md="6" lg="8" xl="9">
              <span>{{ form.catatan_tolak }}</span>
            </b-col>
          </b-row>
        </template>

        <b-row>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>

        <template v-if="form.vendor_type === 1">
          <div class="card mt-4 p-2">
            <b-row class="mt-2">
              <b-col sm="6" md="5" lg="3" xl="2">
                <div>TYPE MAINTENANCE</div>
              </b-col>
              <b-col sm="6" md="5" lg="3" xl="2">
                <b> {{ form.vendor_type === 1 ? "Vendor" : "Internal" }}</b>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col sm="6" md="5" lg="3" xl="2">
                <div>NAMA VENDOR</div>
              </b-col>
              <b-col sm="6" md="5" lg="3" xl="2">
                <b> {{ form.vendor_name || "-" }}</b>
              </b-col>
            </b-row>
          </div>
        </template>

        <b-row>
          <b-col cols="12">
            <span class="font-weight-bold">File Pendukung</span>
          </b-col>
          <b-col cols="12">
            <b-container fluid class="p-0 bg-white">
              <ul class="image-gallery mt-2">
                <li v-bind:key="index" v-for="(file, index) in form.file">
                  <template v-if="file.flag == 'foto' || file.flag == 'video'">
                    <div v-if="file.path.split('.').pop() == 'pdf'">
                      <iframe class="my-iframe" allowfullscreen :src="file.path"></iframe>
                    </div>

                    <div v-else-if="file.path.split('.').pop() == 'mp4'">
                      <iframe class="my-iframe" allowfullscreen :src="file.path"></iframe>
                    </div>

                    <div v-else>
                      <expandable-image :src="file.path" />
                    </div>
                  </template>
                </li>
              </ul>
            </b-container>
          </b-col>

          <b-col cols="12" v-if="lengthBuktiPekerjaan > 0">
            <span class="font-weight-bold">Bukti Pekerjaan</span>

            <b-container fluid class="p-0 bg-white">
              <ul class="image-gallery mt-2">
                <li v-bind:key="index" v-for="(file, index) in buktiPekerjaan">
                  <div v-if="file.path.split('.').pop() === 'pdf'">
                    <iframe class="my-iframe" allowfullscreen :src="file.path"></iframe>
                  </div>

                  <div v-else-if="file.path.split('.').pop() == 'mp4'">
                    <iframe class="my-iframe" allowfullscreen :src="file.path"></iframe>
                  </div>

                  <div v-else>
                    <expandable-image :src="file.path" />
                  </div>
                </li>
              </ul>
            </b-container>
          </b-col>
        </b-row>

        <!-- Table Material Maintenance -->
        <template v-if="lengthMaterial > 0">
          <b-row class="mb-2">
            <b-col cols="12" sm="12" md="12" lg="12" xl="7">
              <span class="font-weight-bold">Daftar Material/Bahan</span>
              <b-table bordered hover show-empty small class="mt-2 mb-3 my-custom-table table-material"
                :fields="fieldsMaterial" :items="form.barang">
                <template #empty="scope"> Data tidak ditemukan </template>

                <template v-slot:cell(no)="row">
                  {{ row.item.no }}
                </template>
              </b-table>
            </b-col>
          </b-row>
        </template>

        <template v-if="lengthMemoMaintenance > 0">
          <b-row class="mb-2">
            <b-col cols="12" sm="12" md="12" lg="12" xl="7">
              <span class="font-weight-bold">Petugas Pemeliharaan</span>
              <b-table bordered hover show-empty small class="mt-2 mb-3 my-custom-table table-user-maintenance"
                :fields="fieldsMaintenance" :items="form.memo_maintenance">
                <template #empty="scope"> Data tidak ditemukan </template>

                <template v-slot:cell(no)="row">
                  {{ row.item.no }}
                </template>

                <template v-slot:cell(tanggal)="row">
                  {{ row.item.tanggal | moment("D MMM Y") }}
                </template>

                <template v-slot:cell(foto)="row">
                  <template v-if="row.item.foto === null">
                    <div>
                      <expandable-image :src="$defaultUser" />
                    </div>
                  </template>

                  <template v-else>
                    <div>
                      <expandable-image :src="row.item.foto" />
                    </div>
                  </template>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </template>

        <!-- Table History Pengajuan -->
        <b-row class="mt-1">
          <b-col cols="12">
            <label class="font-weight-bold">Riwayat Pengajuan</label>
            <b-table bordered hover show-empty small class="mb-3 my-custom-table table-history" :fields="fieldsHistory"
              :items="historyMemo">
              <template #empty="scope"> Data tidak ditemukan </template>

              <template v-slot:cell(tanggal)="row">
                {{ row.item.tanggal | moment("D MMM Y, HH:mm") }}
              </template>

              <template v-slot:cell(status)="row">
                <div v-if="row.item.status == 0">
                  <span class="badge badge-warning">Pengajuan Ditinjau Ulang</span>
                </div>

                <div v-if="row.item.status == 1">
                  <span class="badge badge-primary">Pengajuan Disetujui</span>
                </div>

                <div v-if="row.item.status == 2">
                  <span class="badge badge-primary">Pengajuan Disetujui</span>
                </div>

                <div v-else-if="row.item.status == 3">
                  <span class="badge badge-info text-white">Pengajuan Diproses</span>
                </div>

                <div v-else-if="row.item.status == 4">
                  <span class="badge badge-success text-white">Pengajuan Selesai</span>
                </div>

                <div v-else-if="row.item.status == 8">
                  <span class="badge badge-secondary">Pengajuan Dibatalkan</span>
                </div>

                <div v-else-if="row.item.status == 10">
                  <span class="badge badge-dark text-white">Pengajuan Ditolak</span>
                </div>

                <div v-else-if="row.item.status == 11">
                  <span class="badge badge-danger text-white">Pengajuan Dijadwalkan Ulang</span>
                </div>

                <div v-else-if="row.item.status == 12">
                  <span class="badge badge-success">Petugas Hadir</span>
                </div>

                <div v-else-if="row.item.status == 13">
                  <span class="badge badge-success">Menunggu Penilaian</span>
                </div>
              </template>

            </b-table>
          </b-col>
        </b-row>

        <template v-if="lengthRating > 0">
          <b-row class="mb-2">
            <b-col md="12">
              <span class="font-weight-bold">Ulasan Pekerjaan</span>
            </b-col>

            <b-col md="12" class="mt-3">
              <div v-for="data in form.memo_rating" v-bind:key="data.id">
                <star-rating class="mb-2" :rating="data.rating" :star-size="20" :show-rating="false" :glow="10"
                  :rounded-corners="true"></star-rating>
                <span>{{ data.keterangan }}</span>
              </div>
            </b-col>
          </b-row>
        </template>

        <div class="mt-4 text-right">
          <b-button variant="dark" class="mr-1 ml-1 btn-labeled" @click="hideModal"><span class="btn-label"><i
                class="fa fa-times"></i></span>Tutup</b-button>
        </div>
      </template>
    </b-modal>
    <!-- Modal Update User Maintenance -->
    <b-modal id="m-update-user-maintenance" size="md" title="Ubah Petugas & Tanggal Bertugas" ref="my-modal"
      no-close-on-backdrop no-close-on-esc hide-footer @hidden="hideModal">
      <!-- <LoadingWidget v-if="initStatusUpdateUserMaintenance != 1" :status="initStatusUpdateUserMaintenance"
        @init="showModalUpdateUserMaintenance" /> -->
      <b-form @submit.prevent="handleUpdateVendor" v-if="form.vendor_type === 'Vendor'">
        <b-form-group id="fg-date-maintenance" label="Nama Vendor" label-for="nama-vendor" class="required">
          <b-form-input v-model="formVendor.vendor_name" placeholder="Nama Vendor"></b-form-input>
        </b-form-group>
        <input type="hidden" v-model="formVendor.id">
        <b-form-group id="fg-date-maintenance" label="Tanggal Bertugas" label-for="date-maintenance" class="required">
          <input class="form-control" v-model="formVendor.date" type="date">
        </b-form-group>
        <div class="d-flex justify-content-end">
          <button :disabled="!loadUpdate" class="btn btn-primary">Update</button>
        </div>
      </b-form>
      <b-form @submit.prevent="onConfirm" v-else>
        <b-form-group id="fg-update-user-maintenance" label="Petugas" label-for="update-user-maintenance"
          class="required">
          <multiselect placeholder="Pilih Petugas" class="multiselect" label="name" track-by="id"
            v-model="formUpdate.memo_maintenance" :option-height="104" :taggable="false" :show-labels="false"
            :close-on-select="false" :clear-on-select="false" :preserve-search="true" :multiple="true"
            :options="optionsMaintenance" @tag="addTag" @remove="toggleUnSelectMarket"
            @select="checkValueUserMaintenance">
            <template slot="option" slot-scope="props">
              <template v-if="props.option.status == 1">
                <img class="option__image" :src="props.option.foto
                  " :alt="props.option.foto" />
                <div class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </div>
              </template>
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group id="fg-date-maintenance" label="Tanggal Bertugas" label-for="date-maintenance" class="required">
          <date-picker v-model="formUpdate.date" :config="config" :readonly="lengthNewUserMaintenance > 0"></date-picker>
        </b-form-group>

        <div class="mt-4 text-right">
          <b-button :disabled="loading" type="submit" variant="primary" class="btn-labeled">
            <span class="btn-label" v-show="!loading"><i class="fa fa-save"></i></span>
            <span v-show="loading" class="btn-label">
              <b-spinner small variant="light"></b-spinner>
            </span>
            Simpan Perubahan
          </b-button>

          <b-button variant="dark" class="ml-2 btn-labeled" @click="hideModal"><span class="btn-label"><i
                class="fa fa-times"></i></span>Tutup</b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- Modal Update Material -->
    <b-modal id="m-update-material" size="lg" title="Ubah Material/Bahan" ref="my-modal" no-close-on-backdrop
      no-close-on-esc hide-footer hide-header @hidden="hideModal">
      <LoadingWidget v-if="initStatusUpdateMaterial != 1" :status="initStatusUpdateMaterial"
        @init="showModalUpdateMaterial" />
      <template v-else>
        <b-button v-b-toggle.collapse-update-material class="btn-labeled" variant="primary" @click="collapseAction">
          <template v-if="!collapse">
            <span class="btn-label"><i class="fa fa-chevron-down"></i></span>
          </template>

          <template v-else>
            <span class="btn-label"><i class="fa fa-chevron-up"></i></span>
          </template>
          Tambah Material/Bahan
        </b-button>

        <b-collapse id="collapse-update-material" class="mt-2">
          <b-card class="barang-card" id="card-material">
            <!-- Find Material -->
            <b-row>
              <b-col>
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button variant="success" @click="resultMaterial">
                        <i class="fa fa-search"></i> Cari
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input type="text" placeholder="Nama Material" v-model="findMaterial"
                      @change="resultMaterial"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="light" name="resetTableBtn" @click="onResetMaterial"><i
                          class="fa fa-undo"></i></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Content Material -->
            <b-row v-show="!loadingMaterial">
              <template v-if="lengthFindMaterial > 0">
                <b-col cols="12" sm="12" md="12" lg="6" xl="6" v-for="( data, index ) in  optionsMaterial "
                  v-bind:key="data.id">
                  <b-card :class="data.kelas">
                    <div class="barang-details">
                      <b-row>
                        <b-col cols="9" sm="9" md="9" lg="9" xl="9" style="align-self: center">
                          <span class="font-weight-bold">{{ data.nama }}</span><br />
                          <b-row>
                            <b-col cols="4" sm="4" md="4" lg="4" xl="4">Satuan</b-col>
                            <b-col cols="8" sm="8" md="8" lg="8" xl="8">: {{ data.satuan }}</b-col>
                            <b-col cols="4" sm="4" md="4" lg="4" xl="4">Detail</b-col>
                            <b-col cols="8" sm="8" md="8" lg="8" xl="8">: {{ data.detail }}</b-col>
                            <b-col cols="4" sm="4" md="4" lg="4" xl="4">Stok</b-col>
                            <template v-if="data.stok <= 0 || data.stok == '-'">
                              <b-col cols="8" sm="8" md="8" lg="8" xl="8">:
                                <b-badge variant="danger">Habis</b-badge></b-col>
                            </template>

                            <template v-else>
                              <b-col cols="8" sm="8" md="8" lg="8" xl="8">: {{ data.stok }}</b-col>
                            </template>
                          </b-row>
                        </b-col>
                        <b-col cols="3" sm="3" md="3" lg="3" xl="3" class="text-right" style="align-self: center">
                          <template v-if="data.stok <= 0 || data.stok == '-'">
                            <b-button size="sm" variant="outline-secondary" disabled><i class="fa fa-plus"></i></b-button>
                          </template>

                          <template v-else>
                            <b-button size="sm" variant="outline-success" @click="
                              addToFormMaintenance(
                                data.id_tipe,
                                data.nama,
                                data.stok,
                                data.pic,
                                index
                              )
                              "><i class="fa fa-plus"></i></b-button>
                          </template>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </b-col>
              </template>

              <template v-else>
                <b-col sm="12" class="text-center pt-5 pb-5">
                  <span>Material tidak ditemukan &#128532;</span>
                </b-col>
              </template>
            </b-row>

            <!-- Load Data -->
            <b-row v-show="loadingMaterial" class="text-center">
              <b-col class="mb-5 mt-5">
                <b-spinner small variant="secondary" label="Loading..."></b-spinner>
                <span class="font-weight-bold text-secondary">
                  Harap tunggu...</span>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="10" lg="10" xl="10" class="mb-3">
                <b-pagination v-model="currentPageMaterial" :total-rows="rowsMaterial" :per-page="perPageMaterial"
                  aria-controls="my-table" @input="onPageChangeMaterial"></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-collapse>

        <b-form class="mt-4" @submit.prevent="swtUpdateMaterial">
          <b-row class="mb-5">
            <b-col cols="12" sm="12" md="10" lg="10" xl="10">
              <b-row class="mb-4">
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <span class="font-my-style">Keranjang Material/Bahan</span>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="3" xl="3" class="text-center hide-quantity">
                  <span class="font-weight-bold required">Jumlah</span>
                </b-col>

                <b-col cols="12" sm="12" md="12" lg="3" xl="3" class="text-center hide-action-remove">
                  <span class="font-weight-bold">#</span>
                </b-col>
              </b-row>

              <template v-if="lengthUpdateMaterial >= 1">
                <b-row class="mt-2 mb-2 view-row-cart-mobile" v-bind:key="option.id[index]"
                  v-for="( option, index ) in  formUpdateMaterial.barang ">
                  <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="view-col-cart-mobile" style="align-self: center">
                    <span class="my-name-material">{{ option.tipe }} </span>
                  </b-col>

                  <b-col cols="10" sm="10" md="7" lg="3" xl="3" class="position-stock-mobile view-col-cart-mobile"
                    style="align-self: center">
                    <template>
                      <span class="my-stock">Jumlah: </span>
                      <span> {{ option.quantity }}</span>
                    </template>
                  </b-col>

                  <b-col cols="2" sm="2" md="5" lg="3" xl="3" class="position-trash-mobile view-col-cart-mobile"
                    style="align-self: center">
                    <b-button variant="outline-danger" @click="
                      removeBarangLive(
                        option.id_tipe,
                        index,
                        option.quantity,
                        option.tipe,
                        option.pic,
                        (option.loading = true)
                      )
                      ">
                      <span v-show="!option.loading"><i class="fa fa-trash"></i></span>

                      <span v-show="option.loading" class="spinner-border spinner-border-sm"></span>
                    </b-button>
                  </b-col>
                </b-row>
              </template>

              <template v-else>
                <b-row>
                  <b-col cols="12" class="text-center">
                    <b-badge pill variant="danger">Keranjang material kosong!</b-badge>
                  </b-col>
                </b-row>
              </template>
            </b-col>

            <b-col md="12" class="mt-0 mb-0 hide-action-remove" v-if="lengthBarangNew > 0">
              <hr />
            </b-col>

            <b-col cols="12" sm="12" md="12" lg="10" xl="10">
              <b-row class="mb-4" v-if="lengthBarangNew > 0">
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <span class="font-my-style">Keranjang Material/Bahan Tambahan
                  </span>
                  <b-badge v-b-popover.hover.top="'Material/Bahan tambahan hanya bisa disimpan jika jumlahnya > 0 dan < jumlah stok.'
                    " title="Catatan" pill variant="primary"><i class="fa fa-info"></i></b-badge>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="2" xl="2" class="text-center hide-stock">
                  <span class="font-my-style align-middle">Stok </span>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="3" xl="3" class="text-center hide-quantity">
                  <span class="font-my-style required">Jumlah</span>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="1" xl="1" class="text-center hide-action-remove">
                  <span class="font-my-style">#</span>
                </b-col>
              </b-row>

              <b-row class="mt-2 view-row-cart-mobile" v-bind:key="option.id[index]"
                v-for="( option, index ) in  formUpdateMaterial.id_barang_new ">
                <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="view-col-cart-mobile" style="align-self: center">
                  <span class="my-name-material">{{ option.name }}</span>
                </b-col>

                <b-col cols="7" sm="7" md="8" lg="2" xl="2" class="position-stock-mobile view-col-cart-mobile"
                  style="align-self: center">
                  <template>
                    <span class="my-stock">Stok: </span>
                    <span> {{ option.stock }}</span>
                  </template>
                </b-col>

                <b-col cols="5" sm="5" md="4" lg="3" xl="3"
                  class="text-center d-flex position-quantity-mobile view-col-cart-mobile" style="align-self: center">
                  <b-button class="d-inline-block" variant="outline-success" @click="increment(index, option.stock)"><i
                      class="fa fa-plus"></i></b-button>
                  <b-form-input class="ml-1 mr-1 text-center" type="number"
                    v-model="formUpdateMaterial.quantity_new[index]" :min="1" :max="option.stock" required></b-form-input>
                  <b-button class="d-inline-block" variant="outline-success" @click="decrement(index)"><i
                      class="fa fa-minus"></i></b-button>
                </b-col>

                <b-col cols="12" sm="12" md="1" lg="1" xl="1" class="text-center hide-action-remove"
                  style="align-self: center">
                  <b-button variant="outline-danger" @click="removeBarang(index)">
                    <i class="fa fa-trash"></i>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <div class="text-right">
            <b-button type="submit" variant="primary" class="btn-labeled" :disabled="lengthBarangNew < 1">
              <span class="btn-label" v-show="!loading"><i class="fa fa-save"></i></span>

              <span v-show="loading" class="btn-label">
                <b-spinner small variant="light"></b-spinner>
              </span>
              Simpan Perubahan
            </b-button>

            <b-button variant="dark" class="ml-2 btn-labeled" @click="hideModal"><span class="btn-label"><i
                  class="fa fa-times"></i></span>Tutup</b-button>
          </div>
        </b-form>
      </template>
    </b-modal>

    <b-modal id="modal-laporan" size="md" centered title="Laporan FPP" no-close-on-backdrop no-close-on-esc hide-footer>
      <ModalLaporanFpp />
    </b-modal>
  </div>
</template>

<!-- Kepala Cabang Senior -->
<script>
import axiosClient from "../../config/api"
import ModalLaporanFpp from "../../components/fpp/ModalLaporanFpp.vue";
export default {
  data: () => {
    return {
      initStatus: 0,
      initStatusDetail: 0,
      initStatusUpdateUserMaintenance: 0,
      initStatusUpdateMaterial: 0,
      collapse: false,
      sortBy: "status",
      isMaintenanceChange: false,
      loadUpdate: true,
      roleShowCabang: [
        'General Affair',
        'GM Support',
        'GM General Affair',
        'Super Admin'
      ],
      formVendor: {
        id: null,
        vendor_name: null,
        date: null,
        formatDate: {
          format: "D MMM YYYY",
          useCurrent: true,
          minDate: null
        }
      },
      config: {
        format: "D MMM YYYY",
        useCurrent: true,
        minDate: null
      },
      configFilter: {
        format: "D MMM YYYY",
        useCurrent: true
      },
      fieldsPusat: [
        {
          key: "no_pengajuan",
          label: "No. Pengajuan",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "tanggal",
          label: "Tanggal",
          thClass: "nameOfTheClassThTanggal",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "cabang",
          label: "Cabang",
          thClass: `nameOfTheClassTh`,
          tdClass: `nameOfTheClassTd`,
          sortable: true,
        },
        {
          key: "kepala_cabang",
          label: "Kacab",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "kepala_cabang_senior",
          label: "Kacab Senior",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "kabkot",
          label: "Kabupaten/Kota",
          thClass: "nameOfTheClassTh",
          sortable: true,
        },
        {
          key: "jenis_pengajuan",
          label: "Pengajuan",
          thClass: "nameOfTheClassTh",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "keterangan",
          label: "Keterangan",
          thClass: "nameOfTheClassTh",
          sortable: true,
        },
        {
          key: "aksi",
          label: "Aksi",
          thClass: "nameOfTheClassThAction",
          tdClass: "nameOfTheClassTd",
        },
      ],
      fieldsCb: [
        {
          key: "no_pengajuan",
          label: "No. Pengajuan",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "tanggal",
          label: "Tanggal",
          thClass: "nameOfTheClassThTanggal",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        // {
        //   key: "cabang",
        //   label: "Cabang",
        //   thClass: `nameOfTheClassTh`,
        //   tdClass: `nameOfTheClassTd`,
        //   sortable: true,
        // },
        // {
        //   key: "kepala_cabang",
        //   label: "Kacab",
        //   thClass: "nameOfTheClassTh",
        //   tdClass: "nameOfTheClassTd",
        //   sortable: true,
        // },
        // {
        //   key: "kepala_cabang_senior",
        //   label: "Kacab Senior",
        //   thClass: "nameOfTheClassTh",
        //   tdClass: "nameOfTheClassTd",
        //   sortable: true,
        // },
        {
          key: "kabkot",
          label: "Kabupaten/Kota",
          thClass: "nameOfTheClassTh",
          sortable: true,
        },
        {
          key: "jenis_pengajuan",
          label: "Pengajuan",
          thClass: "nameOfTheClassTh",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "keterangan",
          label: "Keterangan",
          thClass: "nameOfTheClassTh",
          sortable: true,
        },
        {
          key: "aksi",
          label: "Aksi",
          thClass: "nameOfTheClassThAction",
          tdClass: "nameOfTheClassTd",
        },
      ],
      fieldsHistory: [
        {
          key: "tanggal",
          label: "Tanggal",
          thClass: "nameOfTheClassThTanggal",
          tdClass: "nameOfTheClassTd",
        },
        {
          key: "status",
          label: "Status",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
        },
        {
          key: "waktu_proses",
          label: "Waktu Proses",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
        },
        {
          key: "keterangan",
          label: "Keterangan",
          thClass: "nameOfTheClassTh",
        },
        {
          key: "catatan",
          label: "Catatan",
          thClass: "nameOfTheClassTh",
        },
      ],
      fieldsMaintenance: [
        {
          key: "no",
          label: "No",
          thClass: "nameOfTheClassThNo",
          tdClass: "nameOfTheClassTd",
        },
        {
          key: "tanggal",
          label: "Tanggal Bertugas",
          thClass: "nameOfTheClassThTanggal",
          tdClass: "nameOfTheClassTd",
        },
        {
          key: "nama",
          label: "Nama",
          thClass: "nameOfTheClassTh",
        },
        {
          key: "no_telp",
          label: "No. HP",
          thClass: "nameOfTheClassThHP",
          tdClass: "nameOfTheClassTd",
        },
        {
          key: "foto",
          label: "Foto",
          thClass: "nameOfTheClassThFoto",
          tdClass: "nameOfTheClassTd",
        },
      ],
      fieldsMaterial: [
        {
          key: "no",
          label: "No",
          thClass: "nameOfTheClassThNo",
          tdClass: "nameOfTheClassTd",
        },
        {
          key: "material",
          label: "Material/Bahan",
          thClass: "nameOfTheClassTh",
        },
        {
          key: "quantity",
          label: "Jumlah",
          thClass: "nameOfTheClassThQuantity",
          tdClass: "nameOfTheClassTd",
        },
      ],
      form: {
        id: null,
        id_user: null,
        noPengajuan: null,
        cabang: null,
        divisi: "",
        namaKategori: "",
        pengajuan: "",
        kuantitas: null,
        catatan: "",
        catatan_tolak: "",
        catatan_setuju: "",
        status: null,
        file: [],
        memo_rating: [],
        memo_maintenance: [],
        barang_temp: [],
        barang: [],
        estimasi: "",
        timerInProgress: null,
        timerFinish: null,
        vendor_type: null,
        vendor_name: null,
      },
      formFilter: {
        from: null,
        until: null,
        selectFilter: 'status',
        selectJenisKategori: null,
        selectKategori: null,
        selectSubKategori: null,
        selectStatus: null,
        selectKabKot: null,
        selectCabang: [],
        selectKacab: [],
        selectKcs: null,
      },
      formUpdate: {
        id_memo: [],
        id_memo_recycle: "",
        memo_maintenance: [],
        memo_maintenance_temporary: [],
        date: "",
        vendor_type: null,
        vendor_name: null,
        vendor: {},
        vendor_date: null
      },
      formUpdateMaterial: {
        id_memo: [],
        id_memo_recycle: "",
        id_barang: [],
        quantity: [],
        id_barang_new: [],
        quantity_new: [],
        status: [],
        cabang_kode: [],
        barang: [],
        notif: "",
      },
      formDeleteMaterial: {
        id_memo: [],
        id_barang: [],
        cabang_kode: [],
      },
      optionsFilter: [
        {
          value: "cabang",
          label: "Cabang",
        },
        {
          value: "kcs",
          label: "Kepala Cabang Senior",
        },
        {
          value: "kacab",
          label: "Kepala Cabang",
        },
        {
          value: "kabkot",
          label: "Kabupaten/Kota",
        },
        {
          value: "jenis-kategori",
          label: "Jenis Pengajuan",
        },
        {
          value: "kategori",
          label: "Kategori Pengajuan",
        },
        {
          value: "sub-kategori",
          label: "Pengajuan",
        },
        {
          value: "status",
          label: "Status",
        },
      ],
      optionsStatus: [
        {
          value: 3,
          label: "Diproses",
        },
        {
          value: 4,
          label: "Diselesaikan",
        },
        {
          value: 8,
          label: "Dibatalkan",
        },
        {
          value: 10,
          label: "Ditolak",
        },
      ],
      archive: [],
      detailPengajuan: [],
      getKategori: [],
      optionsKacab: [],
      optionsKcs: [],
      optionsCabang: [],
      optionsKabKot: [],
      optionsKategoriJenis: [],
      optionsSubKategori: [],
      optionsMaintenance: [],
      optionsMaterial: [],
      historyMemo: [],
      getCabang: [],
      optionsChecked: [],
      buktiPekerjaan: [],
      page: "",
      size: "",
      from: "",
      limit: "",
      totalViewText: "",
      currentPage: 1,
      perPage: null,
      rows: null,
      pageMaterial: "",
      sizeMaterial: "",
      fromMaterial: "",
      limitMaterial: "",
      currentPageMaterial: 1,
      perPageMaterial: null,
      rowsMaterial: null,
      loading: false,
      loadingMaterial: false,
      url: "",
      param: "",
      query: "",
      searchQuery: "",
      queryMaterial: "",
      lengthBuktiPekerjaan: "",
      lengthFindMaterial: "",
      lengthMaterial: "",
      lengthUpdateMaterial: "",
      lengthMemoMaintenance: "",
      lengthHistory: "",
      lengthRating: "",
      lengthBarangNew: 0,
      lengthNewUserMaintenance: 0,
      namaKategori: "",
      findMaterial: "",
      isBusy: true,
      loadCountDown: false,
      countDownToTime: null,
      timeDifference: null,
      calc: null,
      userId: localStorage.getItem("id"),
      role: localStorage.getItem("role"),
      cabang: JSON.parse(localStorage.getItem("cabang")),
    };
  },
  computed: {
    filtered_cities() {
      return this.$store.state.wilayah.filtered_cities
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },

  components: {
    ModalLaporanFpp
  },
  methods: {
    // Init Function
    init: function () {
      let getAllData = "";
      this.initStatus = 0;
      var today = new Date();
      this.config.minDate = today.setDate(today.getDate());
      // Get Kategori
      getAllData = this.$axios
        .get("api/internal-memo/kategori/all")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            for (var kategori = 0; kategori < datas.length; kategori++) {
              this.getKategori.push({
                id: datas[kategori].id,
                name: datas[kategori].name,
                id_kategori_jenis_fpp: datas[kategori].id_kategori_jenis_fpp,
                sla: datas[kategori].sla,
                kategori_sub: datas[kategori].kategori_sub,
              });
            }
          }
        });

      // Get Archive
      let urlArchive;
      if (this.role == "General Affair") {
        urlArchive = 'api/internal-memo/memo/menu-archive-pic';
      } else {
        urlArchive = 'api/internal-memo/memo/menuArchive';
      }
      getAllData = this.$axios
        .get(urlArchive)
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;
            // PAGINATION BLM SELESAI
            let vendorType;
            for (let data of datas.data) {
              let subKategori =
                data.kategori_sub == null ? "-" : data.kategori_sub.name;

              if (data.vendor_type === 1) {
                vendorType = "Vendor"
              }
              if (data.vendor_type === 0) {
                vendorType = "Internal"
              }
              if (!data.vendor_type) {
                vendorType = "-"
              }
              let newDate = new Date(data.updated_at);
              let countDate = newDate.setDate(newDate.getDate() + 1);

              let resultTime = new Date(countDate).getTime();
              let countDownUpdateTime = resultTime;

              let timeNow = new Date().getTime();
              let resultTimeOut = countDownUpdateTime - timeNow;


              const catatanTolak = data.history_ditolak ? data.history_ditolak.catatan : "-"
              const catatanSetujui = data.history_disetujui ? data.history_disetujui.catatan : "-"
              let keteranganGlobal = data.flag === 10 ? catatanTolak : catatanSetujui
              this.archive.push({
                id: data.id,
                cabang: data.cabang.name,
                kabkot: data.cabang ? data.cabang.kabupaten_kota.name : "-",
                kepala_cabang: data.kepala_cabang ? data.kepala_cabang.name : "-",
                kepala_cabang_senior: data.kepala_cabang_senior ? data.kepala_cabang_senior.name : "-",
                jenis_pengajuan: data.kategori_jenis.name + " " + subKategori,
                catatan: data.catatan,
                tanggal: data.created_at,
                status: data.flag,
                vendor_type: vendorType,
                no_pengajuan: data.im_number,
                keterangan: keteranganGlobal,
                timeout: resultTimeOut,
                count_attendance_maintenance:
                  data.memo_maintenance_count_count,
                total_maintenance: data.total_user_maintenance_count,
              });
            }
            this.perPage = datas.per_page;
            this.size = this.perPage;
            this.from = datas.from;
            this.limit = datas.to;
            this.updateTotalItem(datas.total);
          }
        });

      // Get User Maintenance
      getAllData = this.$axios
        .get("api/internal-memo/user-maintenance")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            for (let x = 0; x < datas.length; x++) {
              if (datas[x].is_active == 1) {
                this.optionsMaintenance.push({
                  id: datas[x].id,
                  name: datas[x].nama,
                  foto: datas[x].foto,
                  status: datas[x].is_active,
                });
              }
            }
          }
        });

      // Get Cabang
      getAllData = this.$axios.get("api/admin/cabang/all").then((response) => {
        if (response.status == 200) {
          let datas = response.data.data;
          for (let cabang = 0; cabang < datas.length; cabang++) {
            this.optionsCabang.push({
              id: datas[cabang].id,
              name: datas[cabang].name,
              checked: false,
            });
          }
        }
      });
      // Get All Kepala Cabang
      getAllData = this.$axios.get("api/admin/management-users/users-role").then((response) => {
        if (response.status == 200) {
          let datas = response.data.data;

          const kacab = datas.filter(item => item.role_id === 4)
          const kcs = datas.filter(item => item.role_id === 5)

          this.optionsKacab = kacab.map(item => {
            return {
              id: item.id,
              text: item.name,
            }
          })
          this.optionsKcs = kcs.map(item => {
            return {
              id: item.id,
              text: item.name,
            }
          })
        }
      });

      // Get Kategori Jenis
      getAllData = this.$axios
        .get("api/internal-memo/kategori-jenis/all")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            for (let jenis = 0; jenis < datas.length; jenis++) {
              this.optionsKategoriJenis.push({
                id: datas[jenis].id,
                name: datas[jenis].name,
              });
            }
          }
        });

      // Get Sub Kategori
      getAllData = this.$axios
        .get("api/internal-memo/kategori-sub/all")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            for (
              let subKategori = 0;
              subKategori < datas.length;
              subKategori++
            ) {
              this.optionsSubKategori.push({
                id: datas[subKategori].id,
                name: datas[subKategori].name,
              });
            }
          }
        });

      // Get Kabupaten/Kota
      getAllData = this.$axios
        .get("api/admin/wilayah/kabupaten")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            for (let kabkot of datas) {
              this.optionsKabKot.push({
                id: kabkot.id,
                text: kabkot.name,
              });
            }
          }
        });

      // Get Material & Stock
      let param = {
        params: { kode_cabang: "0999" },
      };
      getAllData = this.$axios
        .get("api/internal-memo/memo/getStockBarangV2", param)
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;
            let cls = "";
            for (let type of datas.data) {
              if (type.jumlah_stok <= 0) {
                cls = "barang-card card-disabled";
              } else {
                cls = "barang-card";
              }
              this.optionsMaterial.push({
                id: type.id,
                id_tipe: type.id_tipe,
                nama: type.barang_tipe.tipe,
                satuan: type.satuan,
                harga: type.harga,
                stok: type.jumlah_stok,
                detail: type.detail_barang,
                pic: type.pic,
                kelas: cls,
              });
            }
            this.perPageMaterial = datas.per_page;
            this.sizeMaterial = this.perPageMaterial;
            this.fromMaterial = datas.from;
            this.limitMaterial = datas.to;

            this.lengthFindMaterial = datas.total;
            this.updateTotalItemMaterial(datas.total);
          }
        });

      Promise.all([getAllData])
        .then(() => {
          this.initStatus = 1;
          this.toggleBusy();
        })
        .catch((error) => {
          console.log(error);
          this.initStatus = -1;
          this.toggleBusy();
        });
    },
    showModalLaporan() {
      this.$bvModal.show('modal-laporan')
    },

    async handleUpdateVendor() {
      this.loadUpdate = true
      const formData = {
        id: this.formVendor.id,
        vendor_name: this.formVendor.vendor_name,
        date: this.formVendor.date,
      }
      try {
        const response = await axiosClient({
          method: "PUT",
          url: 'internal-memo/memo/update-vendor',
          data: formData
        })
        if (response.status === 200) {
          this.$helper.toastSucc(this, "Success update vendor")
          this.$bvModal.hide("m-update-user-maintenance");
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loadUpdate = false
      }

    },

    async getArchiveByStatus() {
      const roleUser = this.role;
      let status;
      const roleA = ['Area Manager', 'Kepala Cabang Senior', 'Kepala Cabang', 'Kepala Unit']
      const roleB = ['General Affair'];
      const roleC = ['GM Support', 'GM General Affair'];
      if (roleA.includes(roleUser)) {
        status = [4, 8, 10, 12, 13];
      }
      if (roleB.includes(roleUser)) {
        status = [3, 4, 11];
      }
      if (roleC.includes(roleUser)) {
        status = [3, 4, 7, 8, 10, 12, 13];
      }
      const response = await axiosClient({
        method: 'GET',
        url: `internal-memo/memo/paginateKuKc`,
        params: {
          status: status
        }
      })
    },

    onPageChange: function () {
      if (this.loading == false) {
        this.page = this.currentPage;
        this.changeQuery("page", this.page)
        this.filterData();
      }
    },

    toggleBusy() {
      if (this.isBusy == true) {
        this.isBusy = false;
      } else {
        this.isBusy = true;
      }
    },

    updateTotalItem: function (total) {
      this.perPage = this.size;
      this.rows = total;

      const resultFrom = this.from == null ? 0 : this.from;
      const resultLimit = this.limit == null ? 0 : this.limit;

      this.totalViewText = resultFrom + " - " + resultLimit;
    },

    // Pagination, Sorting, dan Filtering Function Material
    onPageChangeMaterial: function () {
      if (this.loadingMaterial == false) {
        this.pageMaterial = this.currentPageMaterial;
        this.queryMaterial = "?page=" + this.pageMaterial;
        this.resultMaterial();
      }
    },

    updateTotalItemMaterial: function (total) {
      this.perPageMaterial = this.sizeMaterial;
      this.rowsMaterial = total;
    },

    // Function Collapse Animation
    collapseAction: function () {
      if (this.collapse === false) {
        this.collapse = true;
      } else {
        this.collapse = false;
      }
    },

    // Custom Input Text to Just Number
    NumbersOnly: (event) => {
      event = event ? event : window.event;
      var charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.preventDefault();
      } else {
        return true;
      }
    },

    // Increment Quantity Material
    increment: function (index, stock) {
      if (this.formUpdateMaterial.quantity_new[index] < stock) {
        let inc = parseInt(this.formUpdateMaterial.quantity_new[index]) + 1;
        this.formUpdateMaterial.quantity_new.splice(index, 1, inc);
      } else {
        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          title: "Perhatian!",
          icon: "warning",
          html: "<small class='text-danger font-weight-bold'>Jumlah material tambahan tidak bisa melebihi stok yang tersedia!</small>",
        });
      }
    },

    // Decrement Quantity Material
    decrement: function (index) {
      let minInput = 1;
      if (this.formUpdateMaterial.quantity_new[index] > minInput) {
        let dec = parseInt(this.formUpdateMaterial.quantity_new[index]) - 1;
        this.formUpdateMaterial.quantity_new.splice(index, 1, dec);
      } else {
        this.removeBarang(index);
      }
    },

    // Result Filter
    resultFilter: function (value) {
      this.toggleBusy();

      if (this.formFilter.selectJenisKategori) {
        this.url =
          "api/internal-memo/memo/menuArchive?id_kategori_jenis_fpp=" +
          this.formFilter.selectJenisKategori;
      } else if (this.formFilter.selectKategori) {
        this.url =
          "api/internal-memo/memo/menuArchive?id_kategori_fpp=" +
          this.formFilter.selectKategori;
      } else if (this.formFilter.selectSubKategori) {
        this.url =
          "api/internal-memo/memo/menuArchive?id_kategori_sub_fpp=" +
          this.formFilter.selectSubKategori;
      } else if (this.formFilter.from && this.formFilter.until) {
        this.url =
          "api/internal-memo/memo/menuArchive?startDate=" +
          this.formFilter.from +
          "&endDate=" +
          this.formFilter.until;
      } else if (this.formFilter.selectKabKot) {
        this.url =
          "api/internal-memo/memo/menuArchive?kabupaten_kota_id=" +
          this.formFilter.selectKabKot;
      } else if (this.formFilter.selectStatus) {
        this.url =
          "api/internal-memo/memo/menuArchive?flag=" +
          this.formFilter.selectStatus;
      } else if (this.formFilter.selectCabang) {
        if (value) {
          let arr = [];
          for (let data of value) {
            arr.push(data.id);
          }
          this.param = {
            params: { id_cabang_multiple: arr },
          };
        }
        if (this.query) {
          this.url = "api/internal-memo/memo/menuArchive" + this.query;
        } else {
          this.url = "api/internal-memo/memo/menuArchive";
        }
      } else {
        this.url = "api/internal-memo/memo/menuArchive" + this.query;
      }

      this.$axios
        .get(this.url, value ? this.param : "")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            this.toggleBusy();
            this.archive = [];
            let vendorType;
            for (let data of datas.data) {
              let subKategori =
                data.kategori_sub == null ? "-" : data.kategori_sub.name;

              if (data.flag == 3) {
                let newDate = new Date(data.updated_at);
                let countDate = newDate.setDate(newDate.getDate() + 1);

                let resultTime = new Date(countDate).getTime();
                let countDownUpdateTime = resultTime;

                let timeNow = new Date().getTime();
                let resultTimeOut = countDownUpdateTime - timeNow;
                const catatanTolak = data.history_ditolak ? data.history_ditolak.catatan : "-"
                const catatanSetujui = data.history_disetujui ? data.history_disetujui.catatan : "-"
                let keteranganGlobal = data.flag === 10 ? catatanTolak : catatanSetujui

                if (data.vendor_type === 1) {
                  vendorType = "Vendor"
                }
                if (data.vendor_type === 0) {
                  vendorType = "Internal"
                }
                if (!data.vendor_type) {
                  vendorType = "-"
                }
                this.archive.push({
                  id: data.id,
                  cabang: data.cabang.name,
                  kabkot: data.cabang ? data.cabang.kabupaten_kota.name : "-",
                  kepala_cabang: data.kepala_cabang ? data.kepala_cabang.name : "-",
                  kepala_cabang_senior: data.kepala_cabang_senior ? data.kepala_cabang_senior.name : "-",
                  jenis_pengajuan: data.kategori_jenis.name + " " + subKategori,
                  catatan: data.catatan,
                  tanggal: data.created_at,
                  status: data.flag,
                  vendor_type: vendorType,
                  no_pengajuan: data.im_number,
                  keterangan: keteranganGlobal,
                  timeout: resultTimeOut,
                  count_attendance_maintenance:
                    data.memo_maintenance_count_count,
                  total_maintenance: data.total_user_maintenance_count,
                });
              } else {
                this.archive.push({
                  id: data.id,
                  cabang: data.cabang.name,
                  kabkot: data.cabang ? data.cabang.kabupaten_kota.name : "-",
                  kepala_cabang: data.kepala_cabang ? data.kepala_cabang.name : "-",
                  kepala_cabang_senior: data.kepala_cabang_senior ? data.kepala_cabang_senior.name : "-",
                  jenis_pengajuan: data.kategori_jenis.name + " " + subKategori,
                  catatan: data.catatan,
                  tanggal: data.created_at,
                  status: data.flag,
                  vendor_type: vendorType,
                  no_pengajuan: data.im_number,
                  timeout: null,
                  count_attendance_maintenance:
                    data.memo_maintenance_count_count,
                  total_maintenance: data.total_user_maintenance_count,
                });
              }
            }

            this.perPage = datas.per_page;
            this.size = this.perPage;
            this.from = datas.from;
            this.limit = datas.to;

            this.updateTotalItem(datas.total);
          }
        })
        .catch((error) => {
          console.log(error);
          this.toggleBusy();
        });
    },

    // changeQuery
    changeQuery: function (type, value) {
      if (type !== "startDate") {
        this.query = null
      }
      if (type !== "endDate") {
        this.query = null
      }
      this.query = { ...this.query, [type]: value }
    },
    filterData: function () {
      this.toggleBusy();
      const F = new Date(this.formFilter.from)
      const U = new Date(this.formFilter.until)

      const FROM = this.formFilter.from ? `${F.getFullYear()}-${F.getMonth() + 1}-${F.getDate()}` : null
      const TO = this.formFilter.until ? `${U.getFullYear()}-${U.getMonth() + 1}-${U.getDate()}` : null
      const ROLE = this.role;
      this.$axios({
        method: "GET",
        url: ROLE === "General Affair" ? `api/internal-memo/memo/menu-archive-pic` : `api/internal-memo/memo/menuArchive`,
        params: {
          ...this.query,
          id_cabang: this.formFilter.selectCabang.map(i => i.id),
          startDate: FROM,
          endDate: TO,

        }
      }).then((response) => {
        if (response.status == 200) {
          let datas = response.data.data;

          let vendorType;
          this.toggleBusy();
          this.archive = [];

          for (let data of datas.data) {
            if (data.vendor_type === 1) {
              vendorType = "Vendor"
            }
            if (data.vendor_type === 0) {
              vendorType = "Internal"
            }
            if (!data.vendor_type) {
              vendorType = "-"
            }

            let subKategori =
              data.kategori_sub == null ? "-" : data.kategori_sub.name;

            let newDate = new Date(data.updated_at);
            let countDate = newDate.setDate(newDate.getDate() + 1);

            let resultTime = new Date(countDate).getTime();
            let countDownUpdateTime = resultTime;

            let timeNow = new Date().getTime();
            let resultTimeOut = countDownUpdateTime - timeNow;
            const catatanTolak = data.history_ditolak ? data.history_ditolak.catatan : "-"
            const catatanSetujui = data.history_disetujui ? data.history_disetujui.catatan : "-"
            let keteranganGlobal = data.flag === 10 ? catatanTolak : catatanSetujui
            this.archive.push({
              id: data.id,
              cabang: data.cabang.name,
              kabkot: data.cabang ? data.cabang.kabupaten_kota.name : "-",
              kepala_cabang: data.kepala_cabang ? data.kepala_cabang.name : "-",
              kepala_cabang_senior: data.kepala_cabang_senior ? data.kepala_cabang_senior.name : "-",
              jenis_pengajuan: data.kategori_jenis.name + " " + subKategori,
              catatan: data.catatan,
              tanggal: data.created_at,
              status: data.flag,
              vendor_type: vendorType,
              no_pengajuan: data.im_number,
              keterangan: keteranganGlobal,
              timeout: resultTimeOut,
              count_attendance_maintenance:
                data.memo_maintenance_count_count,
              total_maintenance: data.total_user_maintenance_count,
            });

            // if (data.flag == 3) {
            //   let newDate = new Date(data.updated_at);
            //   let countDate = newDate.setDate(newDate.getDate() + 1);

            //   let resultTime = new Date(countDate).getTime();
            //   let countDownUpdateTime = resultTime;

            //   let timeNow = new Date().getTime();
            //   let resultTimeOut = countDownUpdateTime - timeNow;

            //   this.archive.push({
            //     id: data.id,
            //     cabang: data.cabang.name,
            //     kabkot: data.cabang ? data.cabang.kabupaten_kota.name : "-",
            //     kepala_cabang: data.kepala_cabang ? data.kepala_cabang.name : "-",
            //     kepala_cabang_senior: data.kepala_cabang_senior ? data.kepala_cabang_senior.name : "-",
            //     jenis_pengajuan: data.kategori_jenis.name + " " + subKategori,
            //     catatan: data.catatan,
            //     tanggal: data.created_at,
            //     status: data.flag,
            //     vendor_type: vendorType,
            //     no_pengajuan: data.im_number,
            //     timeout: resultTimeOut,
            //     count_attendance_maintenance:
            //       data.memo_maintenance_count_count,
            //     total_maintenance: data.total_user_maintenance_count,
            //   });
            // } else {
            //   this.archive.push({
            //     id: data.id,
            //     cabang: data.cabang.name,
            //     kabkot: data.cabang ? data.cabang.kabupaten_kota.name : "-",
            //     kepala_cabang: data.kepala_cabang ? data.kepala_cabang.name : "-",
            //     kepala_cabang_senior: data.kepala_cabang_senior ? data.kepala_cabang_senior.name : "-",
            //     jenis_pengajuan: data.kategori_jenis.name + " " + subKategori,
            //     catatan: data.catatan,
            //     tanggal: data.created_at,
            //     status: data.flag,
            //     vendor_type: vendorType,
            //     no_pengajuan: data.im_number,
            //     timeout: null,
            //     count_attendance_maintenance:
            //       data.memo_maintenance_count_count,
            //     total_maintenance: data.total_user_maintenance_count,
            //   });
            // }
          }

          this.perPage = datas.per_page;
          this.size = this.perPage;
          this.from = datas.from;
          this.limit = datas.to;

          this.updateTotalItem(datas.total);
        }
      })
        .catch((error) => {
          console.log(error);
          this.toggleBusy();
        });
    },

    // Result Material
    resultMaterial: function () {
      if (this.findMaterial != "") {
        this.loadingMaterial = true;

        let param = {
          params: { kode_cabang: "0999", tipe: this.findMaterial },
        };
        this.$axios
          .get(
            "api/internal-memo/memo/getStockBarangV2" + this.queryMaterial,
            param
          )
          .then((response) => {
            if (response.status == 200) {
              let datas = response.data.data;
              let cls = "";
              this.optionsMaterial = [];

              for (let type of datas.data) {
                if (type.jumlah_stok <= 0) {
                  cls = "barang-card card-disabled";
                } else {
                  cls = "barang-card";
                }
                this.optionsMaterial.push({
                  id: type.id,
                  id_tipe: type.id_tipe,
                  nama: type.barang_tipe.tipe,
                  satuan: type.satuan,
                  harga: type.harga,
                  stok: type.jumlah_stok,
                  detail: type.detail_barang,
                  pic: type.pic,
                  kelas: cls,
                });
              }
              this.perPageMaterial = datas.per_page;
              this.sizeMaterial = this.perPageMaterial;
              this.fromMaterial = datas.from;
              this.limitMaterial = datas.to;

              this.lengthFindMaterial = datas.total;
              this.loadingMaterial = false;
              this.updateTotalItemMaterial(datas.total);
            }
          })
          .catch((error) => {
            console.log(error);
            this.loadingMaterial = false;
          });
      } else {
        this.loadingMaterial = true;

        let param = {
          params: { kode_cabang: "0999" },
        };
        this.$axios
          .get(
            "api/internal-memo/memo/getStockBarangV2" + this.queryMaterial,
            param
          )
          .then((response) => {
            if (response.status == 200) {
              let datas = response.data.data;
              let cls = "";

              this.optionsMaterial = [];

              for (let type of datas.data) {
                if (type.jumlah_stok <= 0) {
                  cls = "barang-card card-disabled";
                } else {
                  cls = "barang-card";
                }
                this.optionsMaterial.push({
                  id: type.id,
                  id_tipe: type.id_tipe,
                  nama: type.barang_tipe.tipe,
                  satuan: type.satuan,
                  harga: type.harga,
                  stok: type.jumlah_stok,
                  detail: type.detail_barang,
                  pic: type.pic,
                  kelas: cls,
                });
              }
              this.perPageMaterial = datas.per_page;
              this.sizeMaterial = this.perPageMaterial;
              this.fromMaterial = datas.from;
              this.limitMaterial = datas.to;

              this.lengthFindMaterial = datas.total;
              this.loadingMaterial = false;
              this.updateTotalItemMaterial(datas.total);
            }
          })
          .catch((error) => {
            console.log(error);
            this.loadingMaterial = false;
          });
      }
    },

    // Multiselect Function
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },

    customLabel(option) {
      return `${option.name} - ${option.id}`;
    },

    onSelect(option) {
      let index = this.optionsCabang.findIndex((item) => item.id == option.id);
      this.optionsCabang[index].checked = true;
      this.optionsChecked.push({
        id: option.id,
        checked: option.checked,
      });
    },

    onRemove(option) {
      let index = this.optionsCabang.findIndex((item) => item.id == option.id);
      this.optionsCabang[index].checked = false;
    },

    // Reset All Value Form Filter
    onReset: function () {
      this.formFilter = {
        from: null,
        until: null,
        selectFilter: null,
        selectJenisKategori: null,
        selectKategori: null,
        selectSubKategori: null,
        selectKabKot: null,
        selectStatus: null,
        selectCabang: [],
      };

      for (var data of this.optionsChecked) {
        let index = this.optionsCabang.findIndex((item) => item.id == data.id);
        this.optionsCabang[index].checked = false;
      }

      this.resultFilter();
    },

    // Reset Find Material
    onResetMaterial: function () {
      this.findMaterial = "";
      this.resultMaterial();
    },

    // Reset All Value Form Filter if Change Param Filter
    resetInputFilter: function () {
      this.formFilter.from = null;
      this.formFilter.until = null;
      this.formFilter.selectJenisKategori = null;
      this.formFilter.selectKategori = null;
      this.formFilter.selectSubKategori = null;
      this.formFilter.selectKabKot = null;
      this.formFilter.selectCabang = [];
      for (var data of this.optionsChecked) {
        let index = this.optionsCabang.findIndex((item) => item.id == data.id);
        this.optionsCabang[index].checked = false;
      }
      this.resultFilter();
    },

    // Detail Function
    showModalDetail: function (id) {
      this.initStatusDetail = 0;

      let getDetail = this.$axios
        .get("api/internal-memo/memo/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.loadCountDown = true;
            setInterval(() => {
              this.loadCountDown = false;
            }, 3000);

            let datas = response.data.data;

            let namaKategori = "";
            let proses = "";
            let number = 1;
            let numberMaterial = 1;

            this.form.id = datas.id;
            this.form.id_user = datas.created_by.id;
            this.form.noPengajuan = datas.im_number;
            this.form.cabang = datas.cabang.name ? datas.cabang.name : "-";
            this.form.divisi = datas.devisi.nm_Divisi;
            this.form.namaKategori = namaKategori;
            this.form.pengajuan = datas.kategori_sub ? datas.kategori_sub.name : '-';
            this.form.kuantitas = datas.qty ? datas.qty : "-";
            this.form.prosesTerakhir = datas.list_history_memo;
            this.form.catatan = datas.catatan;
            this.form.catatan_tolak = datas.catatan_tolak;
            this.form.catatan_setuju = datas.catatan_setuju;
            this.form.status = datas.flag;
            this.form.file = datas.memo_file;
            this.form.memo_rating = datas.memo_rating;
            this.form.vendor_type = datas.vendor_type;
            this.form.vendor_name = datas.vendor_type === 1 ? datas.internal_memo_vendor.vendor_name : null;

            for (let dataFile of this.form.file) {
              if (
                dataFile.flag === "foto_pic_ku" ||
                dataFile.flag === "video_pic_ku"
              ) {
                this.buktiPekerjaan.push({
                  flag: dataFile.flag,
                  path: dataFile.path,
                });
              }
            }
            if (datas.barang) {
              for (let x of datas.barang) {
                if (x) {
                  this.form.barang.push({
                    no: numberMaterial++,
                    material: x.tipe,
                    quantity: x.quantity,
                  });

                }
              }
            }

            for (let dataMemoMaintenance of datas.memo_maintenance) {
              for (let dataUserMaintenance of dataMemoMaintenance.user_maintenance) {
                this.form.memo_maintenance.push({
                  id: dataUserMaintenance.id,
                  no: number++,
                  tanggal: dataMemoMaintenance.date,
                  nama: dataUserMaintenance.nama,
                  no_telp: dataUserMaintenance.no_telp,
                  kode: dataMemoMaintenance.kode,
                  foto: dataUserMaintenance.foto,
                });
              }
            }

            for (let dataHistory of datas.list_history_memo) {
              let splitOneDigit = dataHistory.waktu_proses.split(":").pop();
              let splitFrontDigit = dataHistory.waktu_proses.split(":")[0];

              if (
                splitOneDigit == "0" ||
                splitOneDigit == "1" ||
                splitOneDigit == "2" ||
                splitOneDigit == "3" ||
                splitOneDigit == "4" ||
                splitOneDigit == "5" ||
                splitOneDigit == "6" ||
                splitOneDigit == "7" ||
                splitOneDigit == "8" ||
                splitOneDigit == "9"
              ) {
                proses = splitFrontDigit + ":" + "0" + splitOneDigit;
              } else {
                proses = splitFrontDigit + ":" + splitOneDigit;
              }

              this.historyMemo.push({
                tanggal: dataHistory.created_at,
                status: dataHistory.status,
                keterangan: dataHistory.keterangan,
                catatan: dataHistory.catatan,
                waktu_proses: proses,
              });
            }

            let cekTimeByFlag = this.historyMemo.find(
              (flag) =>
                flag.status == 4 || flag.status == 8 || flag.status == 10
            );

            this.form.namaKategori = datas.kategori_jenis.name;
            this.form.estimasi = datas.kategori_sub ? datas.kategori_sub.sla + " hari" : "-";

            var createMemo = new Date(datas.created_at);
            var millisecondsInOneSecond = 1000;
            var millisecondsInOneMinute = millisecondsInOneSecond * 60;
            var millisecondsInOneHour = millisecondsInOneMinute * 60;
            var millisecondsInOneDay = millisecondsInOneHour * 24;

            var differenceInDays = "";
            var remainderDifferenceInHours = "";
            var remainderDifferenceInMinutes = "";
            var remainderDifferenceInSeconds = "";
            var remainingDays = "";
            var remainingHours = "";
            var remainingMinutes = "";
            var remainingSeconds = "";
            var countDate = datas.kategori_sub ? createMemo.setDate(
              createMemo.getDate() + datas.kategori_sub.sla
            ) : '-';
            var resultTime = new Date(countDate).getTime();
            this.countDownToTime = resultTime;

            if (datas.flag != 4 && datas.flag != 8 && datas.flag != 10) {
              var countDown = setInterval(() => {
                var timeNow = new Date().getTime();
                var resultTimeOut = this.countDownToTime - timeNow;

                differenceInDays = resultTimeOut / millisecondsInOneDay;
                remainderDifferenceInHours =
                  (resultTimeOut % millisecondsInOneDay) /
                  millisecondsInOneHour;
                remainderDifferenceInMinutes =
                  (resultTimeOut % millisecondsInOneHour) /
                  millisecondsInOneMinute;
                remainderDifferenceInSeconds =
                  (resultTimeOut % millisecondsInOneMinute) /
                  millisecondsInOneSecond;
                remainingDays = Math.floor(differenceInDays);
                remainingHours = Math.floor(remainderDifferenceInHours);
                remainingMinutes = Math.floor(remainderDifferenceInMinutes);
                remainingSeconds = Math.floor(remainderDifferenceInSeconds);

                if (remainingDays == 0) {
                  this.form.timerInProgress =
                    remainingHours +
                    ":" +
                    remainingMinutes +
                    ":" +
                    remainingSeconds;
                } else {
                  this.form.timerInProgress =
                    remainingDays +
                    " hari, " +
                    remainingHours +
                    ":" +
                    remainingMinutes +
                    ":" +
                    remainingSeconds;
                }

                if (resultTimeOut < 0) {
                  var timeDifference = timeNow - this.countDownToTime;

                  differenceInDays = timeDifference / millisecondsInOneDay;
                  remainderDifferenceInHours =
                    (timeDifference % millisecondsInOneDay) /
                    millisecondsInOneHour;
                  remainderDifferenceInMinutes =
                    (timeDifference % millisecondsInOneHour) /
                    millisecondsInOneMinute;
                  remainderDifferenceInSeconds =
                    (timeDifference % millisecondsInOneMinute) /
                    millisecondsInOneSecond;
                  remainingDays = Math.floor(differenceInDays);
                  remainingHours = Math.floor(remainderDifferenceInHours);
                  remainingMinutes = Math.floor(remainderDifferenceInMinutes);
                  remainingSeconds = Math.floor(remainderDifferenceInSeconds);

                  if (remainingDays == 0) {
                    this.form.timerInProgress =
                      "Lewat " +
                      remainingHours +
                      ":" +
                      remainingMinutes +
                      ":" +
                      remainingSeconds;
                  } else {
                    this.form.timerInProgress =
                      "Lewat " +
                      remainingDays +
                      " hari, " +
                      remainingHours +
                      ":" +
                      remainingMinutes +
                      ":" +
                      remainingSeconds;
                  }
                }
                this.timeDifference = resultTimeOut;
              }, 1000);
            } else {
              clearInterval(countDown);

              var getTimeByFlag = new Date(cekTimeByFlag.tanggal).getTime();
              var calc = getTimeByFlag - resultTime;

              differenceInDays = calc / millisecondsInOneDay;
              remainderDifferenceInHours =
                (calc % millisecondsInOneDay) / millisecondsInOneHour;
              remainderDifferenceInMinutes =
                (calc % millisecondsInOneHour) / millisecondsInOneMinute;
              remainderDifferenceInSeconds =
                (calc % millisecondsInOneMinute) / millisecondsInOneSecond;
              remainingDays = Math.floor(differenceInDays);
              remainingHours = Math.floor(remainderDifferenceInHours);
              remainingMinutes = Math.floor(remainderDifferenceInMinutes);
              remainingSeconds = Math.floor(remainderDifferenceInSeconds);

              this.calc = calc;
              if (remainingDays == 0) {
                this.form.timerFinish =
                  "Lewat " +
                  remainingHours +
                  ":" +
                  remainingMinutes +
                  ":" +
                  remainingSeconds;
              } else {
                this.form.timerFinish =
                  "Lewat " +
                  remainingDays +
                  " hari, " +
                  remainingHours +
                  ":" +
                  remainingMinutes +
                  ":" +
                  remainingSeconds;
              }
            }

            this.lengthRating = this.form.memo_rating.length;
            this.lengthMaterial = datas.barang ? datas.barang.length : 0;
            this.lengthBuktiPekerjaan = this.buktiPekerjaan.length;
            this.lengthMemoMaintenance = this.form.memo_maintenance.length;
            this.lengthHistory = this.historyMemo.length;
          }
        });
      Promise.all([getDetail])
        .then(() => {
          this.initStatusDetail = 1;
        })
        .catch((error) => {
          console.log(error);
          this.initStatusDetail = -1;
        });

      this.$bvModal.show("m-detail");
    },

    // Hide Modal Function
    hideModal: function () {
      this.$refs["my-modal"].hide();
      this.onResetAllForm();
    },

    onResetAllForm: function () {
      this.form = {
        id: null,
        id_user: null,
        noPengajuan: null,
        cabang: null,
        divisi: "",
        namaKategori: "",
        pengajuan: "",
        kuantitas: null,
        catatan: "",
        status: null,
        file: [],
        memo_rating: [],
        memo_maintenance: [],
        barang: [],
        estimasi: "",
        timerInProgress: null,
        timerFinish: null,
      };

      this.formUpdate = {
        id_memo: [],
        id_memo_recycle: "",
        memo_maintenance: [],
        memo_maintenance_temporary: [],
      };

      this.formUpdateMaterial = {
        id_memo: [],
        id_memo_recycle: "",
        id_barang: [],
        quantity: [],
        id_barang_new: [],
        quantity_new: [],
        cabang_kode: [],
        barang: [],
        status: [],
      };

      this.formDeleteMaterial = {
        id_memo: [],
        id_barang: [],
        cabang_kode: [],
      };

      this.historyMemo = [];
      this.buktiPekerjaan = [];
      this.findMaterial = "";
      this.countDownToTime = null;
      this.timeDifference = null;
      this.calc = null;
      this.lengthNewUserMaintenance = 0;
    },

    // Remove User Maintenance Function
    toggleUnSelectMarket: function (value) {
      let arrUserMaintenance = [];
      let idDetail = this.formUpdate.id_memo_recycle;

      // this.loading = true;

      arrUserMaintenance.push(value.id);
      let param = {
        id_memo: this.formUpdate.id_memo,
        id_user_maintenance: arrUserMaintenance,
      };
      this.$axios
        .post("/api/internal-memo/memo/deleteUserMaintenance", param)
        .then((response) => {
          if (response.status == 200) {
            // this.formUpdate.id_memo = [];
            this.formUpdate.id_memo_recycle = null;
            this.formUpdate.memo_maintenance_temporary = [];
            // this.formUpdate.memo_maintenance = [];
            // this.formUpdate.date = "";
            this.lengthNewUserMaintenance = 0;
            this.showModalUpdateUserMaintenance(idDetail, data);
            // this.loading = false;
          }
        });
    },

    // Modal Changes User Maintenance
    showModalUpdateUserMaintenance: function (id, { data }) {
      const vendorType = data.vendor_type;
      this.form.vendor_type = vendorType;
      this.loading = false;
      this.initStatusUpdateUserMaintenance = 0;
      this.lengthNewUserMaintenance = 0;
      this.formUpdate.id_memo_recycle = id;
      this.formUpdate.id_memo.push(id);

      let getUsermaintenance = this.$axios
        .get("api/internal-memo/memo/" + id)
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;
            this.formUpdate.vendor_type = datas.vendor_type;
            this.formUpdate.vendor_name = datas.vendor_type === 1 ? datas.internal_memo_vendor.vendor_name : null;
            this.formUpdate.vendor = datas.vendor_type === 1 ? datas.internal_memo_vendor : {};
            if (datas.vendor_type === 1) {
              this.formVendor.id = datas.internal_memo_vendor.id
              this.formVendor.vendor_name = datas.internal_memo_vendor.vendor_name
              this.formVendor.date = datas.internal_memo_vendor.date
            }
            for (let data of datas.memo_maintenance) {
              for (let user of data.user_maintenance) {
                this.formUpdate.memo_maintenance.push({
                  id: user.id,
                  name: user.nama,
                });
                const formatDate = new Date(data.date);
                this.formUpdate.date = formatDate;

                this.formUpdate.memo_maintenance_temporary.push({
                  id: user.id,
                  name: user.nama,
                });
              }
            }
          }
        });

      Promise.all([getUsermaintenance])
        .then(() => {
          this.initStatusUpdateUserMaintenance = 1;
        })
        .catch((error) => {
          console.log(error);
          this.initStatusUpdateUserMaintenance = -1;
        });
      this.$bvModal.show("m-update-user-maintenance");
    },

    checkValueUserMaintenance: function () {
      this.lengthNewUserMaintenance =
        this.formUpdate.memo_maintenance_temporary.length;
    },

    // Submit Changer User Maintenance & Date
    onConfirm: function () {
      let arrMaintenance = [];
      let arrMaintenanceTemp = [];

      for (let temp of this.formUpdate.memo_maintenance_temporary) {
        arrMaintenanceTemp.push(temp.id);
      }
      for (let data of this.formUpdate.memo_maintenance) {
        arrMaintenance.push(data.id);
      }

      var final = arrMaintenance.filter(function (item) {
        for (var i = 0; i < arrMaintenanceTemp.length; i++) {
          if (arrMaintenanceTemp[i] === item) return false;
        }
        return true;
      });

      if (final.length < 1) {
        return;
      }



      const formatDate = new Date(this.formUpdate.date);
      let resultDate = this.$moment(formatDate).format("YYYY-MM-D");

      var Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
      });

      let param;
      let idDetail = this.formUpdate.id_memo_recycle;
      let idMemo = this.formUpdate.id_memo

      if (final.length > 0) {
        param = {
          id_memo: idMemo,
          id_user_maintenance: final,
          date: resultDate,
        };
      } else {
        param = {
          id_memo: idMemo,
          id_user_maintenance: arrMaintenance,
          date: resultDate,
        };
      }

      this.loading = true;
      this.$axios
        .post("/api/internal-memo/memo/createUserMaitenance", param)
        .then((response) => {
          if (response.status == 200) {
            Toast.fire({
              title: "Sukses!",
              iconHtml: `
                    <div class="m-2">
                      <i class="fa fa-check"></i>
                    </div>
                  `,
              iconColor: '#a5dc86',
              html: "<small>Perubahan berhasil.</small>",
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonColor: "#1e73be",
              cancelButtonText: "Nanti",
              confirmButtonText: "Lihat Perubahan",
            }).then((result) => {
              if (result.isConfirmed) {
                this.showModalDetail(idDetail);
              } else {
                this.onResetAllForm();
              }
            });

            this.loading = false;
            this.formUpdate.id_memo = [];
            this.formUpdate.memo_maintenance = [];
            this.formUpdate.date = "";
            this.lengthNewUserMaintenance = 0;

            this.$bvModal.hide("m-update-user-maintenance");
          }
        })
        .catch((error) => {
          Toast.fire({
            title: "Gagal!",
            iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
            iconColor: '#dc3545',
            html: "<small>Perubahan gagal!</small>",
          });
        });
    },

    // Add Material to List
    addToFormMaintenance: function (id, nama, stok, pic) {
      var defaultQuantity = 1;

      var Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      if (this.formUpdateMaterial.id_barang_new.length > 0) {
        let checkId = this.formUpdateMaterial.id_barang_new.find(
          (item) => item.id === id
        );

        if (checkId) {
          let checkIndeks = (element) => element.id === id;
          let indeks =
            this.formUpdateMaterial.id_barang_new.findIndex(checkIndeks);
          if (this.formUpdateMaterial.quantity_new[indeks] < stok) {
            let lastQuantity =
              parseInt(this.formUpdateMaterial.quantity_new[indeks]) +
              defaultQuantity;
            this.formUpdateMaterial.quantity_new.splice(
              indeks,
              1,
              lastQuantity
            );
            Toast.fire({
              title: "Selamat!",
              icon: "success",
              html:
                "<small class='font-weight-bold'>(" +
                lastQuantity +
                ") Material " +
                nama +
                " </small><br/><small>berhasil ditambahkan ke dalam keranjang</small>",
            });
          } else {
            Toast.fire({
              title: "Perhatian!",
              icon: "warning",
              html: "<small class='text-danger'>Jumlah material tambahan tidak bisa melebihi dari stok yang tersedia!</small>",
            });
          }
        } else {
          this.formUpdateMaterial.id_barang_new.push({
            id: id,
            name: nama,
            stock: stok,
          });
          this.formUpdateMaterial.quantity_new.push(defaultQuantity);
          this.formUpdateMaterial.cabang_kode.push(pic);
          this.formUpdateMaterial.status.push({
            status: true,
          });
          Toast.fire({
            title: "Selamat!",
            icon: "success",
            html:
              "<small class='font-weight-bold'>(" +
              defaultQuantity +
              ") Material " +
              nama +
              " </small><br/><small>berhasil ditambahkan ke dalam keranjang</small>",
          });
        }
      } else {
        this.formUpdateMaterial.id_barang_new.push({
          id: id,
          name: nama,
          stock: stok,
        });
        this.formUpdateMaterial.quantity_new.push(defaultQuantity);
        this.formUpdateMaterial.cabang_kode.push(pic);
        this.formUpdateMaterial.status.push({
          status: true,
        });
        Toast.fire({
          title: "Selamat!",
          icon: "success",
          html:
            "<small class='font-weight-bold'>(" +
            defaultQuantity +
            ") Material " +
            nama +
            " </small><br/><small>berhasil ditambahkan ke dalam keranjang</small>",
        });
      }
      this.lengthBarangNew = this.formUpdateMaterial.id_barang_new.length;
    },

    // Remove Material Live
    removeBarangLive: function (id_barang, index, qty, material, cabang_kode) {
      var Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      this.formDeleteMaterial.id_barang.push(id_barang);
      this.formDeleteMaterial.cabang_kode.push(cabang_kode.toString());
      this.formDeleteMaterial.id_memo.push(
        this.formUpdateMaterial.id_memo_recycle
      );

      let param = {
        id_memo: this.formDeleteMaterial.id_memo,
        id_barang: this.formDeleteMaterial.id_barang,
        cabang_kode: this.formDeleteMaterial.cabang_kode,
      };

      this.$axios
        .post("/api/internal-memo/memo/deleteBarangMaintenance", param)
        .then((response) => {
          if (response.status == 200) {
            Toast.fire({
              title: "Selamat!",
              icon: "success",
              html:
                "<small class='font-weight-bold'>(" +
                qty +
                ") Material " +
                material +
                " </small><small>berhasil dihapus dari keranjang</small>",
            });

            this.formUpdateMaterial.barang.splice(index, 1);
            this.formUpdateMaterial.quantity.splice(index, 1);
            this.formUpdateMaterial.cabang_kode.splice(index, 1);

            this.resultMaterial();
            this.formDeleteMaterial.id_barang = [];
            this.formDeleteMaterial.cabang_kode = [];
            this.lengthUpdateMaterial = this.formUpdateMaterial.barang.length;
          }
        })
        .catch((error) => {
          console.log(error);
          Toast.fire({
            title: "Gagal!",
            iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
            iconColor: '#dc3545',
            html: "<small>Material gagal dihapus!</small>",
          });
        });
    },

    // Remove Material Temporary
    removeBarang: function (index) {
      this.formUpdateMaterial.id_barang_new.splice(index, 1);
      this.formUpdateMaterial.quantity_new.splice(index, 1);
      this.formUpdateMaterial.cabang_kode.splice(index, 1);
      this.lengthBarangNew = this.formUpdateMaterial.id_barang_new.length;
    },

    // Modal Changes Material
    showModalUpdateMaterial: function (id) {
      this.initStatusUpdateMaterial = 0;
      this.formUpdateMaterial.id_memo.push(id);
      this.formUpdateMaterial.id_memo_recycle = id;

      let getMaterial = this.$axios
        .get("api/internal-memo/memo/" + id)
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            if (datas.barang.length >= 1) {
              for (let data of datas.barang) {
                this.formUpdateMaterial.barang.push({
                  id: data.id,
                  id_tipe: data.id_tipe,
                  id_barang: data.id_barang,
                  tipe: data.tipe,
                  quantity: data.quantity,
                  pic: data.pic,
                  loading: false,
                });
              }
            }

            this.lengthUpdateMaterial = this.formUpdateMaterial.barang.length;
          }
        });

      Promise.all([getMaterial])
        .then(() => {
          this.initStatusUpdateMaterial = 1;
        })
        .catch((error) => {
          console.log(error);
          this.initStatusUpdateMaterial = -1;
        });

      this.$bvModal.show("m-update-material");
    },

    // Submit Changes Material
    swtUpdateMaterial: function () {
      let arrMemo = [];
      let arrBarang = [];
      let arrQty = [];
      let arrCabangKode = [];

      for (let memo of this.formUpdateMaterial.id_memo) {
        arrMemo.push(memo);
      }
      for (let barang of this.formUpdateMaterial.id_barang_new) {
        arrBarang.push(barang.id);
      }
      for (let qty of this.formUpdateMaterial.quantity_new) {
        arrQty.push(parseInt(qty));
      }
      for (let kode of this.formUpdateMaterial.cabang_kode) {
        arrCabangKode.push(kode);
      }

      if (arrBarang.length > 0) {
        let param = {
          id_memo: arrMemo,
          id_barang: arrBarang,
          quantity: arrQty,
          cabang_kode: arrCabangKode,
        };

        var Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
        });

        this.loading = true;

        this.$axios
          .post("/api/internal-memo/memo/createBarangMaintenance", param)
          .then((response) => {
            if (response.status == 200) {
              let idDetail = this.formUpdateMaterial.id_memo_recycle;

              Toast.fire({
                title: "Sukses!",
                icon: "success",
                html: "<small>Material/Bahan berhasil ditambahkan ke dalam keranjang.</small>",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Nanti",
                confirmButtonText: "Lihat Perubahan",
              }).then((result) => {
                if (result.isConfirmed) {
                  // this.showModalUpdateMaterial(idDetail);
                  this.showModalDetail(idDetail);
                } else {
                  // this.hideModal();
                  this.onResetAllForm();
                }
              });

              this.loading = false;
              this.findMaterial = "";
              this.lengthBarangNew = 0;
              this.formUpdateMaterial.id_memo = [];
              this.formUpdateMaterial.id_barang = [];
              this.formUpdateMaterial.quantity = [];
              this.formUpdateMaterial.barang = [];
              this.formUpdateMaterial.id_barang_new = [];
              this.formUpdateMaterial.quantity_new = [];
              this.formUpdateMaterial.cabang_kode = [];
              this.formUpdateMaterial.status = [];

              this.resultMaterial();
              this.$bvModal.hide("m-update-material");
            }
          })
          .catch((error) => {
            console.log(error);
            Toast.fire({
              title: "Sukses!",
              icon: "success",
              html: "<small>Perubahan material/bahan gagal!</small>",
            });
          });
      }
    },
  },
  created() {
    this.$store.dispatch("getCityFiltered")
    this.init();
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.b-table-empty-row {
  text-align: center;
}

.table th,
.table td {
  vertical-align: middle;
}

.nameOfTheClassThHP {
  width: 15%;
  text-align: center;
}

.nameOfTheClassTh {
  text-align: center;
}

.nameOfTheClassTd {
  text-align: center;
}

.nameOfTheClassThNo {
  width: 50px;
  text-align: center;
}

.nameOfTheClassThQuantity {
  width: 12%;
  text-align: center;
}

.nameOfTheClassThAction {
  width: 10%;
  text-align: center;
}

.nameOfTheClassThTanggal {
  width: 18%;
  text-align: center;
}

.nameOfTheClassThSelect {
  width: 5%;
  text-align: center;
}

.nameOfTheClassThFoto {
  width: 12%;
  text-align: center;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-upload {
  padding: 8px 20px;
  background-color: #808080;
  border: none;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  transition: 100ms ease-out;
  cursor: pointer;
}

.upload-btn-wrapper:hover {
  border-radius: 5px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.btn-label {
  position: relative;
  left: -12px;
  display: inline-block;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem 0 0 0.25rem;
}

.btn-labeled {
  padding-top: unset;
  padding-bottom: unset;
}

.show-icon {
  display: none;
}

.multiselect {
  width: 100%;
}

.my-multiselect {
  width: auto;
  display: inline-block;
  vertical-align: middle;
}

.multiselect__tag {
  background: #1e73be;
  color: #fff;
}

.multiselect__option--highlight {
  background: #1e73be;
  outline: none;
  color: #fff;
}

.option__desc,
.option__image {
  display: inline-block;
  vertical-align: middle;
}

.option__image {
  height: 80px;
  width: 60px;
  object-fit: cover;
  margin-right: 10px;
}

.checkbox-label {
  display: block;
}

.custom-check {
  position: absolute;
  right: 1vw;
}

.barang-card {
  box-shadow: 0 0.1875rem 1rem rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  overflow: hidden;
}

#card-material .card-body {
  padding: 10px 10px 10px;
}

.card-disabled {
  background-color: rgba(245, 245, 245, 1);
  opacity: 0.5;
}

.swal2-popup.swal2-toast .swal2-html-container {
  margin: 0.1em 1em;
}

.swal2-popup.swal2-toast .swal2-title {
  margin: 0.1em 1em;
}

.image-gallery {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 10px;
  background: #e4e5e630;
  padding: 10px;
  border-radius: 5px;
  overflow-x: scroll;
}

.image-gallery>li {
  flex-basis: 250px;
  list-style: none;
}

.image-gallery li .expandable-image img {
  object-fit: cover;
  width: 250px;
  height: 250px;
  vertical-align: middle;
  border-radius: 5px;
}

div .expandable-image img {
  height: 65px;
  object-fit: cover;
}

.dropdown-item {
  position: relative;
  padding: 5px 5px;
  border-bottom: 1px solid #c8ced3;
}

.position-stock-mobile {
  text-align: center;
}

.position-trash-mobile {
  text-align: center;
}

.font-my-style {
  font-weight: bold;
}

.my-name-material {
  font-weight: normal;
}

.my-stock {
  display: none;
}

@media only screen and (max-width: 992px) {
  .btn-labeled-for-mobile {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }

  .show-icon {
    display: inline-block;
  }

  .table-bordered {
    border: unset;
  }

  .multiselect {
    width: auto;
    display: block;
  }

  .my-filter {
    display: none;
  }

  .check-all-for-mobile {
    display: block;
    margin-bottom: 0.5rem;
    margin-right: 10px;
  }

  .hide-quantity {
    display: none;
  }

  .hide-stock {
    display: none;
  }

  .hide-action-remove {
    display: none;
  }

  .view-row-cart-mobile {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 0;
    margin-right: 0;
  }

  .view-col-cart-mobile {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
  }

  .position-stock-mobile {
    text-align: left;
    margin-bottom: 15px;
  }

  .position-quantity-mobile {
    margin-inline-start: auto;
    margin-bottom: 15px;
  }

  .position-trash-mobile {
    text-align: right;
    margin-bottom: 15px;
  }

  .font-my-style {
    font-weight: normal;
  }

  .my-name-material {
    font-weight: bold;
  }

  .my-stock {
    display: inline-block;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1024px) {
  .check-all-for-mobile {
    display: block;
    margin-bottom: 0.5rem;
  }

  .styled-checkbox+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 16px;
    height: 16px;
    background: white;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
  }

  .pagination-wrapper .input-group-text {
    display: block;
  }

  .image-gallery>li {
    flex-basis: 100px;
    list-style: none;
  }

  .image-gallery li .expandable-image img {
    object-fit: cover;
    width: 100px;
    height: 100px;
    vertical-align: middle;
    border-radius: 5px;
  }

  div .expandable-image img {
    width: 150px;
    height: 200px;
    object-fit: cover;
  }

  .my-iframe {
    width: 100px;
    height: 100px;
  }

  .my-custom-table tbody th,
  .my-custom-table tbody td {
    position: relative;
  }

  .my-custom-table th,
  .my-custom-table td {
    padding: 0.6rem;
    vertical-align: middle;
  }

  /* Force table to not be like tables anymore */
  .my-custom-table thead,
  .my-custom-table tbody,
  .my-custom-table th,
  .my-custom-table td,
  .my-custom-table tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .my-custom-table thead tr,
  .my-custom-table tfoot tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .my-custom-table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    text-align: left;
  }

  .my-custom-table td:before {
    position: absolute;
    top: 50%;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    left: 25%;
    margin: 0;
    margin: 0;
    transform: translate(-50%, -50%);
  }

  .my-custom-table td:nth-child(1) {
    height: 100%;
    top: 0;
    left: 0;
    font-weight: bold;
    text-align: left;
    background-color: #304f7b;
    color: white;
  }

  .table-material td:nth-of-type(1):before {
    content: "No.";
  }

  .table-material td:nth-of-type(2):before {
    content: "Material/Bahan";
  }

  .table-material td:nth-of-type(3):before {
    content: "Jumlah";
  }

  .table-user-maintenance td:nth-of-type(1):before {
    content: "No.";
  }

  .table-user-maintenance td:nth-of-type(2):before {
    content: "Tanggal Bertugas";
  }

  .table-user-maintenance td:nth-of-type(3):before {
    content: "Nama";
  }

  .table-user-maintenance td:nth-of-type(4):before {
    content: "No. HP";
  }

  .table-user-maintenance td:nth-of-type(5):before {
    content: "Kode";
  }

  .table-user-maintenance td:nth-of-type(6):before {
    content: "Foto";
  }

  .table-history td:nth-of-type(1):before {
    content: "Tanggal";
  }

  .table-history td:nth-of-type(2):before {
    content: "Status";
  }

  .table-history td:nth-of-type(3):before {
    content: "Waktu Proses";
  }

  .table-history td:nth-of-type(4):before {
    content: "Keterangan";
  }

  .table-daftar-fpp td:nth-of-type(1):before {
    content: "No. Pengajuan";
  }

  .table-daftar-fpp td:nth-of-type(2):before {
    content: "Tanggal";
  }


  .table-daftar-fpp td:nth-of-type(3):before {
    content: "Cabang";
  }

  .table-daftar-fpp td:nth-of-type(4):before {
    content: "Kabupaten/Kota";
  }

  .table-daftar-fpp td:nth-of-type(5):before {
    content: "Pengajuan";
  }

  .table-daftar-fpp td:nth-of-type(6):before {
    content: "Status";
  }

  .table-daftar-fpp td:nth-of-type(7):before {
    content: "Keterangan";
  }

  .table-daftar-fpp td:nth-of-type(8):before {
    content: "Aksi";
  }
}
</style>